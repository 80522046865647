import React                                           from 'react';
import { graphql, useStaticQuery }                     from 'gatsby';
import Img                                             from 'gatsby-image';
import { Flex, Box, Heading, UnorderedList, ListItem } from '@chakra-ui/react';

import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';

import * as S from './styles';

const About = () => {
  const data = useStaticQuery(query);

  return (
    <S.Container>
      <Flex flexWrap='wrap'>
        <Box width={['100%', '100%', '50%', '50%']}>
          <Img fluid={data.portrait.media[0].file.localFile.childImageSharp.fluid}
               alt="Portrait von Isabelle Permantier"/>
        </Box>
        <Box width={['100%', '100%', '50%', '50%']} pl={20}>

          <Heading as="h2">Isabelle Permantier</Heading>
          <Spacer height={20}/>
          <UnorderedList spacing={5}>
            <ListItem>...studierte Mode- und Grafikdesignerin</ListItem>
            <ListItem>...Jungunternehmerin</ListItem>
            <ListItem>...Kreativkopf mit Herz</ListItem>
            <ListItem>...Naturverbunden</ListItem>
            <ListItem>...Kölnerin mit Hätz und Seel</ListItem>
          </UnorderedList>
        </Box>
      </Flex>
    </S.Container>
  )
};

export default About;

const query = graphql`
    query {
        portrait: directusMediaCollection(name: {eq: "ip-portrait"}) {
            name
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 960, quality: 95) {
                                base64
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                sizes
                            }
                        }
                    }
                }
            }
        }
    }
`;