import React    from 'react';
import { Text } from '@chakra-ui/react';

import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';

import About from '../components/About/About';

const AboutPage = () => {
  return (
    <Wrapper style={{ textAlign: 'justify', maxWidth: '960px' }}>
      <Spacer/>
      <Heading as="h1">IP Urbankids</Heading>
      <Spacer height={20}/>
      <Text>Junges Start-Up Modelabel aus Köln, gegründet von studierter Mode- und Grafikdesignerin Isabelle
        Permantier.
        Kreativität, Nachhaltigkeit & Individualität stehen für mich an erster Stelle.
        Personalisierbar & einzigartig für Groß- und Klein</Text>
      <Spacer height={20}/>
      <Text>
        Kreativität, Design und hoher Tragecomfort unter Beachtung aller Nachhaltigkeits-, Fairtrade- und
        Umweltschutzaspekten, entworfen und gefertigt in liebevoller Handarbeit in Köln Deutschland.</Text>
      <Spacer height={20}/>
      <Text>
        Handmade in Köln Deutschland aus ausschliesslich regional eingekauften Stoffen in Bioqualität und Materialien.
        Alle Artikel sind durch eine hochwertige Stickerei oder einen Transferdruck personalisierbar
        Alle verwendeten Materialien und Stoffe sind verifiziert, schadstofffrei und entsprechen dem Standard 100
        nach <b>OEKO-TEX®</b>
      </Text>
      <Spacer/>
      <About/>

    </Wrapper>
  )
}

export default AboutPage;
